import { useEffect } from 'react';
import Header from './Header/Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const Layout = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return (
        <>
            <Header />
            <div id="page" className="site page-wrapper">
                {children}
            </div>
            <Footer />
            <div
                id="woosw_wishlist"
                className="woosw-popup woosw-popup-center"
            />
            <link
                rel="stylesheet"
                id="wc-blocks-style-css"
                href="/wp-content/plugins/woocommerce/assets/client/blocks/wc-blocks8fea.css?ver=11.8.0-dev"
                media="all"
            />
            <link
                rel="stylesheet"
                id="elementor-post-1007-css"
                href="/wp-content/uploads/elementor/css/post-1007b5d2.css?ver=1706442188"
                media="all"
            />
            <link
                rel="stylesheet"
                id="elementor-icons-shared-0-css"
                href="/wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min52d5.css?ver=5.15.3"
                media="all"
            />
            <link
                rel="stylesheet"
                id="elementor-icons-fa-brands-css"
                href="/wp-content/plugins/elementor/assets/lib/font-awesome/css/brands.min52d5.css?ver=5.15.3"
                media="all"
            />
        </>
    );
};

export default Layout;
