import Layout from '../components/common/Layout';
import Breadcrumb from '../components/common/Breadcrumb';
import MissionVisionSection from '../components/about/MissionVisionSection';
import StatisticsSection from '../components/about/StatisticsSection';
import BestCoursesSection from '../components/about/BestCoursesSection';
import TestimonialSection from '../components/about/TestimonialSection';
import '../assets/css/about.css';
import {Helmet} from "react-helmet";

const AboutPage = () => {
    return (
        <Layout>
            <Helmet>
                <title>
                    About | Creative Insight Academy
                </title>
                <meta
                    property="title"
                    content="About | Creative Insight Academy"
                />
                <meta name="description" content="Discover the heart of Creative Insight Academy. Our story, values, and mission come to life in our About page. Learn about the inspiration that drives us to provide top-quality education and support for your academic aspirations."/>
            </Helmet>
            <Breadcrumb title="About" startPath="Creative Insight" endPath="about" />
            <h1 style={{display:"none"}}>Creative Insight</h1>
            <div
                data-elementor-type="wp-page"
                data-elementor-id={24}
                className="elementor elementor-24"
            >
                <MissionVisionSection />
                <StatisticsSection />
                <BestCoursesSection />
                <TestimonialSection />
                {/*<OurTeamSection />*/}
            </div>
        </Layout>
    );
};

export default AboutPage;
