import AppDevelopmentImage from '../assets/images/courses-images/appDevelopment.jpg';
import PhpImage from '../assets/images/courses-images/php.png';
import UiUxImage from '../assets/images/courses-images/uxui.jpg';
import NodeJsImage from '../assets/images/courses-images/nodejs.png';
import QaImage from '../assets/images/courses-images/QA.svg';
import FullstackImage from '../assets/images/courses-images/fullStackDevelopment.jpg';
import LaravelImage from '../assets/images/courses-images/laravel.jpg';
import ReactJsImage from '../assets/images/courses-images/reactjs.png';
import WebDesigningImage from '../assets/images/courses-images/webDesigning.jpg';
import NetImage from '../assets/images/courses-images/netDevelopment.png';
import WebDevelopmentImage from '../assets/images/courses-images/webDevelopment.jpg';
import studentImage1 from '../assets/images/student-images/student1.jpg';
import studentImage2 from '../assets/images/student-images/student2.jpg';
import studentImage3 from '../assets/images/student-images/student3.jpg';
import studentImage4 from '../assets/images/student-images/student4.png';

export const footerSocialLinks = [
    {
        id: 1,
        link: 'https://www.facebook.com/people/Creative-Insight-Academy/61557796626733/?sk=about',
        icon: 'fab fa-facebook-f',
        target: '_blank',
    },
    {
        id: 2,
        link: 'https://www.linkedin.com/company/creative-insight-it-academy/',
        icon: 'fab fa-linkedin',
        target: '_blank',
    },
    {
        id: 3,
        link: 'https://www.instagram.com/creative_insight_it_academy/',
        icon: 'fab fa-instagram',
        target: '_blank',
    },
    {
        id: 4,
        link: 'https://wa.me/9601917960',
        icon: 'fab fa-whatsapp',
        target: '_blank',
    },
    {
        id: 5,
        link: 'https://www.youtube.com/@CreativeInsightITAcademy',
        icon: 'fab fa-youtube',
        target: '_blank',
    },
];

export const footerQuickLinks = [
    {
        id: 1,
        path: '/courses',
        title: 'Latest Courses',
    },
    {
        id: 2,
        path: '/about',
        title: 'Mission & Vision',
    },
    /*{
        id: 3,
        path: '/gallery',
        title: 'Gallery',
    },*/
    {
        id: 4,
        path: '/courses',
        title: 'Join A Career',
    },
];

export const footerExploreLinks = [
    {
        id: 1,
        path: '/about',
        title: 'About Us',
    },
    // {
    //     id: 2,
    //     path: '/events',
    //     title: 'Upcoming Events',
    // },
    {
        id: 3,
        path: '/faq',
        title: 'FAQ Questions',
    },
    // {
    //     id: 4,
    //     path: '/testimonials',
    //     title: 'Testimonials',
    // },
    {
        id: 5,
        path: '/contact',
        title: 'Contact Us',
    },
];

export const headerLinks = [
    {
        id: 1,
        path: '/',
        hasChildren: false,
        title: 'Home',
        subMenu: [],
    },
    {
        id: 2,
        path: '',
        hasChildren: true,
        title: 'Courses',
        subMenu: [
            {
                id: 1,
                path: '/course?course=fullStackDev',
                title: 'Full Stack Development',
                key: 'fullStackDev',
            },
            {
                id: 2,
                path: '/course?course=webDev',
                title: 'Advance Web Development',
                key: 'webDev',
            },
            {
                id: 3,
                path: '/course?course=webDesigning',
                title: 'Advance Web Designing',
                key: 'webDesigning',
            },
            {
                id: 9,
                path: '/course?course=appDevelopment',
                title: 'App Development',
                key: 'appDevelopment',
            },
            {
                id: 4,
                path: '/course?course=reactJs',
                title: 'React.js',
                key: 'reactJs',
            },
            {
                id: 5,
                path: '/course?course=nodeJs',
                title: 'Node.js',
                key: 'nodeJs',
            },
            {
                id: 6,
                path: '/course?course=uiUx',
                title: 'Ui/Ux & Graphic Designing',
                key: 'uiUx',
            },
            {
                id: 11,
                path: '/course?course=net',
                title: '.Net Development',
                key: 'net',
            },
            {
                id: 7,
                path: '/course?course=php',
                title: 'Core PHP',
                key: 'php',
            },
            {
                id: 8,
                path: '/course?course=laravel',
                title: 'Advance Laravel',
                key: 'laravel',
            },
            {
                id: 10,
                path: '/course?course=qa',
                title: 'QA',
                key: 'qa',
            },
        ],
    },
    {
        id: 3,
        path: '/gallery',
        hasChildren: false,
        title: 'Gallery',
        subMenu: [],
    },
    // {
    //     id: 4,
    //     path: '/events',
    //     hasChildren: false,
    //     title: 'Events',
    //     subMenu: [],
    // },
    {
        id: 5,
        path: '/about',
        hasChildren: false,
        title: 'About',
        subMenu: [],
    },
    {
        id: 6,
        path: '/contact',
        hasChildren: false,
        title: 'Contact',
        subMenu: [],
    },
];

export const serviceCardData = [
    {
        id: 1,
        title: 'Placement Assistance',
        content:
            'Gain a competitive edge in the job market with our personalized guidance and support every step of the way.',
        link: '',
        icon: '/icons/placementAssistance.svg',
        delay: '100ms',
    },
    {
        id: 2,
        title: 'Personalized Attention',
        content:
            'Receive individualized support tailored to your needs and goals, with personalized attention from Creative Insight IT Academy to help you excel in your IT career journey.',
        link: '',
        icon: '/icons/personalizedAttention.svg',
        delay: '200ms',
    },
    {
        id: 3,
        title: 'Interview Support',
        content:
            'Prepare for IT interviews with mock sessions and technical skill refreshers, plus get personalized guidance on resumes and networking strategies from Creative Insight IT Academy.',
        link: '/',
        icon: '/icons/interviewSupport.svg',
        delay: '300ms',
    },
    {
        id: 4,
        title: 'Soft Skill Improvement',
        content:
            'Refine your soft skills and enhance your professional presence with our targeted training programs.',
        link: '/',
        icon: '/icons/softSkillIcon.svg',
        delay: '400ms',
    },
];

export const teamMemberData = [
    {
        id: 1,
        facebook: '',
        linkedin: '',
        youtube: '',
        role: 'role1',
        name: 'name1',
        image: '',
        link: '',
    },
    {
        id: 2,
        facebook: '',
        linkedin: '',
        youtube: '',
        role: 'role2',
        name: 'name2',
        image: '',
        link: '',
    },
    {
        id: 3,
        facebook: '',
        linkedin: '',
        youtube: '',
        role: 'role3',
        name: 'name3',
        image: '',
        link: '',
    },
];

export const aboutUsData = [
    {
        id: 1,
        title: 'Industrial Visit',
        icon: '/icons/industrialVisitIcon.svg',
    },
    {
        id: 2,
        title: 'Interview Practice',
        icon: '/icons/interviewSupport.svg',
    },
    {
        id: 3,
        title: 'Lifetime Support',
        icon: '/icons/lifeTimeSupportIcon.svg',
    },
    {
        id: 4,
        title: 'Expert Lectures',
        icon: '/icons/personalizedAttention.svg',
    },
];

export const missionVisionData = [
    {
        id: 1,
        title: 'Our Mission',
        description:
            "To emerge as the premier global institute, dedicated to equipping every student with industry-ready skills, empowering them to thrive in the dynamic and competitive landscape of tomorrow's workforce.",
        icon: 'icon-logical-thinking',
    },
    {
        id: 1,
        title: 'Our Vision',
        description:
            "Our mission is to revolutionize education by tailoring skill development to each student's unique strengths, passions, and aspirations. Through pioneering methodologies and personalized learning experiences, we unlock doors to global opportunities, empowering students to realize their full potential and embark on fulfilling, impactful careers on the world stage.",
        icon: 'icon-vision',
    },
];

export const statisticData = [
    {
        id: 1,
        count: '15+',
        title: 'APPROVED COURSES',
        delay: '100ms',
    },
    {
        id: 2,
        count: '10+',
        title: 'EXPERT TRAINER',
        delay: '200ms',
    },
    {
        id: 3,
        count: '100+',
        title: 'Placement Partners',
        delay: '300ms',
    },
    {
        id: 4,
        count: '98%',
        title: 'Excellence',
        delay: '400ms',
    },
];

export const favoriteTopicsData = [
    {
        id: 1,
        icon: '/icons/uiUxIcon.svg',
        title: 'Designing',
        courseCount: '3',
        imagePath: '/wp-content/uploads/2023/09/category-normal-1.png',
        hoverImagePath: '/wp-content/uploads/2023/09/category-hover-1.png',
        link: '/course?course=webDesigning',
    },
    {
        id: 2,
        icon: '/icons/webDevelopment.svg',
        title: 'Web Development',
        courseCount: '4',
        imagePath: '/wp-content/uploads/2023/09/category-normal-2.png',
        hoverImagePath: '/wp-content/uploads/2023/09/category-hover-2.png',
        link: '/course?course=webDev',
    },
    {
        id: 3,
        icon: '/icons/fullStackIcon.svg',
        title: 'Full Stack Development',
        courseCount: '2',
        imagePath: '/wp-content/uploads/2023/09/category-normal-3.png',
        hoverImagePath: '/wp-content/uploads/2023/09/category-hover-3.png',
        link: '/course?course=fullStackDev',
    },
    {
        id: 4,
        icon: '/icons/appDevelopmentIcon.svg',
        title: 'App Development',
        courseCount: '3',
        imagePath: '/wp-content/uploads/2023/09/category-normal-4.png',
        hoverImagePath: '/wp-content/uploads/2023/09/category-hover-4.png',
        link: '/course?course=appDevelopment',
    },
];

export const testimonialData = [
    {
        id: 1,
        studentImage: studentImage1,
        review: 'Creative Insight IT Academy is fantastic! The teachers are supportive, the courses are practical, and the hands-on projects make learning enjoyable. Truly the best IT academy in Rajkot!',
        name: 'Krushi Padaliya',
        role: '.NET Developer',
    },
    {
        id: 2,
        studentImage: studentImage2,
        review: 'I recommend Creative Insight IT Academy! This IT institute provides top-notch education with a strong emphasis on hands-on learning and innovation, making it the best place to start a tech career.',
        name: 'Vrunda Vachhani',
        role: ' React.js Developer',
    },
    {
        id: 3,
        studentImage: studentImage3,
        review: "I'm so glad I chose Creative Insight IT Academy. The curriculum is up-to-date with industry standards, and the small class sizes ensure personalized attention from knowledgeable instructors",
        name: 'Meet Rank',
        role: 'Laravel Developer',
    },
    {
        id: 4,
        studentImage: studentImage4,
        review: 'For anyone starting a tech career, Creative Insight IT Academy is the top IT institute in Rajkot. The instructors provide individual attention, and the practical projects are excellent',
        name: 'Rajan Chovatiya',
        role: 'Full Stack Developer',
    },
];

export const fullCourseDetails = [
    {
        id: 1,
        title: 'App Development',
        imagePath: AppDevelopmentImage,
        key: 'appDevelopment',
        link: '/course?course=appDevelopment',
        description:
            'App development involves creating software applications that run on various platforms like mobile devices, desktops, and web browsers. It encompasses designing, coding, testing, and deploying apps using programming languages, frameworks, and tools suited for each platform. Key components include user interface (UI) design, backend development, database management, and integration of functionalities like user authentication, data storage, and communication with servers. Successful app development requires understanding user needs, following development best practices, and keeping up with technological advancements.',
        subTitle: 'App Development Core Concepts',
        concepts: [
            'Mobile App Development Platforms',
            'User Interface (UI) Design Principles',
            'User Experience (UX) Design',
            'Frontend Development (HTML, CSS, JavaScript)',
            'Backend Development (Node.js, Python, Ruby on Rails, PHP)',
            'Native App Development (iOS, Android)',
            'Cross-Platform Development (React Native, Flutter)',
            'Version Control and Collaboration (Git, GitHub)',
        ],
        duration: '8 Months',
        skillLevel: 'Beginner',
        language: 'English',
    },
    {
        id: 2,
        title: 'Web Designing',
        imagePath: WebDesigningImage,
        key: 'webDesigning',
        link: '/course?course=webDesigning',
        description:
            'Web design involves creating and arranging elements on a webpage to achieve a visually appealing and functional layout. This includes choosing colors, fonts, images, and overall style to create an engaging user experience. Web designers often use tools like HTML, CSS, and JavaScript to bring their designs to life and ensure compatibility across different devices and browsers. They focus on usability, accessibility, and aesthetics to create websites that are both visually appealing and easy to navigate.',
        subTitle: 'Web Design Core Concepts',
        concepts: [
            'Adobe Photoshop',
            'Adobe Illustrator',
            'Logo Design',
            'Brand Design',
            'Portfolio Management',
            '2 Live Projects',
        ],
        duration: '6 Months',
        skillLevel: 'Beginner',
        language: 'English',
    },
    {
        id: 10,
        title: 'Web Development',
        imagePath: WebDevelopmentImage,
        key: 'webDev',
        link: '/course?course=webDev',
        description:
            'Web development involves creating websites and web applications. It encompasses various technologies and skills such as HTML, CSS, and JavaScript for building the user interface, while backend development involves languages like Python, PHP, or Node.js for server-side logic and database management. Frontend development focuses on what users see and interact with, while backend development deals with the server-side operations and database management. Full-stack development combines both frontend and backend skills to create fully functional web applications.',
        subTitle: 'Web Development Core Concepts',
        concepts: [
            'HTML, CSS, JavaScript',
            'JavaScript ES6',
            'Web Development Frameworks',
            'Bootstrap',
            'Database Management',
        ],
        duration: '8 Months',
        skillLevel: 'Beginner',
        language: 'English',
    },
    {
        id: 3,
        title: 'Full Stack Development',
        imagePath: FullstackImage,
        key: 'fullStackDev',
        link: '/course?course=fullStackDev',
        description:
            'Full-stack development involves both frontend and backend work in web app creation, covering UI design, interactive features, database management, server logic, and API creation. Developers use HTML, CSS, JavaScript, Node.js, Python, or Java for coding, along with databases like MySQL or MongoDB. Frameworks like React, Angular, or Express.js streamline development, ensuring seamless communication between frontend and backend for scalable, feature-rich web apps meeting modern user needs.',
        subTitle: 'Full Stack Development Course Contents',
        concepts: [
            'HTML 5',
            'CSS3',
            'Tailwind CSS',
            'Bootstrap',
            'JavaScript',
            'PSD to HTML',
            'React JS',
            'Expert in React JS',
            'Expert in Node JS',
            'API Reference',
            'Database',
            'React DOM',
            'Git Management',
            'Performance Hooks',
            'Data Structure',
            '5+ Live Projects',
        ],
        duration: '10 Months',
        skillLevel: 'Beginner',
        language: 'English',
    },
    {
        id: 4,
        title: 'Laravel',
        imagePath: LaravelImage,
        key: 'laravel',
        link: '/course?course=laravel',
        description:
            'Laravel is a popular open-source PHP framework known for its simplicity, elegance, and robust features. It follows the MVC (Model-View-Controller) architectural pattern, making it easy to build web applications with clean and structured code. Laravel provides a wide range of tools and libraries for tasks like routing, database management (using Eloquent ORM), authentication, caching, and more, which helps developers work efficiently and quickly create scalable and maintainable applications.',
        subTitle: 'Laravel Framework Core Concepts',
        concepts: [
            'Intro to PHP Programming',
            'Basics of Android Development',
            'Fundamentals of PHP',
            'Datatypes',
            'Variables & Constants',
            'Introduction of Operators',
            'Logical Operator',
            'Types of Comments',
            'Control Structure ',
            'Array & Functions',
            'Database with SQL',
            'Blade Template',
            'Admin Panel',
        ],
        duration: '6 Months',
        skillLevel: 'Beginner',
        language: 'English',
    },
    {
        id: 5,
        title: 'Node Js',
        imagePath: NodeJsImage,
        key: 'nodeJs',
        link: '/course?course=nodeJs',
        description:
            "Node.js is a powerful runtime environment that allows you to run JavaScript code outside of a web browser. It's built on Chrome's V8 JavaScript engine and uses an event-driven, non-blocking I/O model, making it efficient and ideal for building scalable network applications. Node.js is commonly used for server-side development, enabling developers to create web servers, APIs, and other backend services using JavaScript.",
        subTitle: 'Node.js Core Concepts',
        concepts: [
            'Node.js Process Model',
            'Install Node.js on Windows, Mac',
            'Node.js Console/REPL',
            'Node.js Basics',
            'Node.js Modules',
            'Local Modules in Node.js',
            'Export Modules in Node.js',
            'Node Package Manager (NPM)',
            'Debugging Node.js Application',
            'Node Inspector for Debugging Node.js Application',
            'Node.js EventEmitter',
            'Reselect and Reducer Hooks',
            'Open-source Frameworks for Node.js',
            'Express.js',
            'Express.js Web App',
            'Serving Static Files from Node.js',
            'Node.js Learning Resources',
        ],
        duration: '6 Months',
        skillLevel: 'Beginner',
        language: 'English',
    },
    {
        id: 6,
        title: 'Php',
        imagePath: PhpImage,
        key: 'php',
        link: '/course?course=php',
        description:
            "PHP, or Hypertext Preprocessor, is a popular server-side scripting language used for web development. It's particularly well-suited for creating dynamic web pages and interacting with databases. PHP code is embedded within HTML, allowing developers to mix logic with presentation seamlessly. It supports various databases, has a vast ecosystem of libraries and frameworks, and is widely used in building dynamic websites and web applications.",
        subTitle: 'Php Core Concepts',
        concepts: [
            'Introduction of PHP',
            'Control structures: Logical Expressions',
            'Control structures: Loops',
            'Exploring Data Type',
            'User Define Function',
            'Working with Cookies And Sessions',
            'Database Connectivity with MySQL',
            'Convert HTML File into PHP',
            'OOPS Concept',
            'Working with files and Directories',
            'Sending Mails',
            'Introduction of JQuery/Ajax/JavaScript',
            'Client-side (JS & JQ) and Server Side (PHP) Validation',
            '2 Live Projects',
        ],

        duration: '3-4 Months',
        skillLevel: 'Beginner',
        language: 'English',
    },
    {
        id: 11,
        title: '.Net',
        imagePath: NetImage,
        key: 'net',
        link: '/course?course=net',
        description:
            '.NET development refers to software development using the .NET framework, a versatile platform created by Microsoft. It supports various programming languages like C#, Visual Basic, and F#, enabling developers to build a wide range of applications, including web applications, desktop applications, mobile apps, and backend services.',
        subTitle: '.Net Development Core Concepts',
        concepts: [
            'Database SQL Server',
            'OOPS',
            'C#',
            '.NET Framework',
            '.NET MVC',
            '.NET Core',
            'Entity Framework',
            'GitHub',
            'JIRA Board',
        ],
        duration: '6 Months',
        skillLevel: 'Beginner',
        language: 'English',
    },
    {
        id: 7,
        title: 'QA',
        imagePath: QaImage,
        key: 'qa',
        link: '/course?course=qa',
        description:
            'Quality assurance (QA) is a process-oriented approach to ensuring that software or products meet defined quality standards. It involves systematic monitoring and evaluation of processes to prevent defects and errors, with the goal of delivering high-quality outcomes to users or customers. QA encompasses activities like testing, auditing, and process improvement to identify and address issues early in the development lifecycle.',
        subTitle: 'Quality Assurance Core Concepts',
        concepts: [
            'Introduction to Quality Assurance (QA)',
            'Software Testing Fundamentals',
            'Test Case Design and Execution',
            'Test Automation Tools and Frameworks (e.g., Selenium, Appium)',
            'Defect Tracking and Management',
            'Performance Testing',
            'Usability Testing',
            'Agile Testing Practices',
        ],
        duration: '8 Months',
        skillLevel: 'Beginner',
        language: 'English',
    },
    {
        id: 8,
        title: 'React Js',
        imagePath: ReactJsImage,
        key: 'reactJs',
        link: '/course?course=reactJs',
        description:
            'React can be used to develop single-page, mobile, or server-rendered applications with frameworks like Next.js. Because React is only concerned with the user interface and rendering components to the DOM, React applications often rely on libraries for routing and other client-side functionality. A key advantage of React is that it only rerenders those parts of the page that have changed, avoiding unnecessary rerendering of unchanged DOM elements.',
        subTitle: 'React.js Core Concepts',
        concepts: [
            'Intro to React.JS',
            'Environment of ReactJS',
            'ReactJS Lifecycle',
            'Intro to State and Props',
            'React Events',
            'Form Handling',
            'Array and Object handling',
            'Local Storage',
            'Routing and Navigation',
            'Reselect and Reducer Hooks',
            'API Integration',
            'Live Project',
        ],
        duration: '6 Months',
        skillLevel: 'Beginner',
        language: 'English',
    },
    {
        id: 9,
        title: 'Ui Ux',
        imagePath: UiUxImage,
        key: 'uiUx',
        link: '/course?course=uiUx',
        description:
            'UI/UX, or User Interface/User Experience, refers to the design and usability aspects of digital products such as websites and apps. UI focuses on the look and feel, including colors, layouts, and typography, while UX deals with the overall user experience, including ease of use, navigation, and user satisfaction. Good UI/UX design enhances user engagement, satisfaction, and retention, leading to better product adoption and success.',
        subTitle: 'UI/UX Core Concepts',
        concepts: [
            'Adobe Photoshop',
            'Adobe Illustrator',
            'Adobe XD',
            'Figma',
            'Mobile application layout',
            'Responsive web application layout',
            'Portfolio management',
            '5+ live projects',
        ],
        duration: '6 Months',
        skillLevel: 'Beginner',
        language: 'English',
    },
];

export const Tabs = {
    ALL: 'All',
    STUDENTS: 'Students',
    EVENTS: 'Events',
    OFFICE: 'Office',
    // VIDEO: 'Video',
};

export const galleryImages = [
    {
        type: Tabs.OFFICE,
        src: '/images/office/office2.jpg',
    },
    {
        type: Tabs.EVENTS,
        src: '/images/event/office1.jpg',
    },
    {
        type: Tabs.OFFICE,
        src: '/images/office/office3.jpg',
    },
    {
        type: Tabs.OFFICE,
        src: '/images/office/office4.jpg',
    },
    {
        type: Tabs.OFFICE,
        src: '/images/office/office5.jpg',
    },
    {
        type: Tabs.STUDENTS,
        src: '/images/welcome/welcome_urvisha.png',
    },
    {
        type: Tabs.STUDENTS,
        src: '/images/welcome/welcome_neel.png',
    },
    {
        type: Tabs.STUDENTS,
        src: '/images/welcome/welcome_krupali.png',
    },
    {
        type: Tabs.STUDENTS,
        src: '/images/welcome/welcome_krushi.png',
    },
    {
        type: Tabs.STUDENTS,
        src: '/images/welcome/welcome_rajan.png',
    },
    {
        type: Tabs.STUDENTS,
        src: '/images/welcome/welcome_rutu.png',
    },
    {
        type: Tabs.STUDENTS,
        src: '/images/welcome/welcome_rutvi.png',
    },
    {
        type: Tabs.STUDENTS,
        src: '/images/welcome/welcome_sahil.png',
    },
    {
        type: Tabs.STUDENTS,
        src: '/images/welcome/welcome_vrunda.png',
    },
    {
        type: Tabs.STUDENTS,
        src: '/images/welcome/welcome_mahek.png',
    },
    // {
    //     type: Tabs.VIDEO,
    //     src: '/videos/video1.mp4',
    // },
];
