import { Helmet } from 'react-helmet';
import Layout from '../components/common/Layout';
import HeroBanner from '../components/home/HeroBanner';
import ServiceCards from '../components/home/ServiceCards';
import AboutSection from '../components/home/AboutSection';
import CoursesSection from '../components/home/CoursesSection';
import TestimonialsSection from '../components/home/TestimonialsSection';
import CirtificateSection from '../components/home/CirtificateSection';
import '../assets/css/home.css';

const HomePage = () => {
    return (
        <Layout>
            <Helmet>
                <title>
                    IT Training Courses in Rajkot | Best Coding classes -
                    CreativeInsight
                </title>
                <meta
                    property="og:title"
                    content="Best IT Institute in Rajkot| Best Coding classes - CreativeInsight"
                />
                <meta
                    property="og:description"
                    content="Best IT Training Institute in Rajkot. Learn web development, React.js, Node.js, PHP, .net, Graphic Design, UI/UX and other IT skills at the Best IT Institute."
                />
                <meta
                    name="author"
                    content="Best IT Institute - CreativeInsight IT Academy"
                />
            </Helmet>
            <div
                data-elementor-type="wp-page"
                data-elementor-id={1204}
                className="elementor elementor-1204"
            >
                <h1 style={{ display: 'none' }}>Creative Insight</h1>
                <HeroBanner />
                <ServiceCards />
                <AboutSection />
                <CoursesSection />
                <TestimonialsSection />
                {/*<TeamSection />*/}
                <CirtificateSection />
            </div>
        </Layout>
    );
};

export default HomePage;
