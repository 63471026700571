import Layout from '../components/common/Layout';
import Breadcrumb from '../components/common/Breadcrumb';
import { useState } from 'react';
import { galleryImages, Tabs } from '../utils/data';

const Gallery = () => {
    const [tab, setTab] = useState(Tabs.ALL);
    return (
        <Layout>
            <Breadcrumb
                title="Gallery"
                startPath="Creative Insight"
                endPath="gallery"
            />
            <div className="my-5 d-flex justify-content-center">
                {Object.values(Tabs).map((tabName) => (
                    <button
                        key={tabName}
                        onClick={() => setTab(tabName)}
                        style={{
                            textUnderlineOffset: '0.5rem',
                            color: '#f57005',
                        }}
                        className={`btn bg-transparent shadow-none border-0 ${tab === tabName ? 'text-decoration-underline' : 'btn-secondary text-black'}`}
                    >
                        {tabName}
                    </button>
                ))}
            </div>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns:
                        'repeat(auto-fit, minmax(300px, max-content))',
                    justifyContent: 'center', // Center when all fit in one row
                    alignItems: 'center',
                }}
                className="gap-5 px-4 my-5"
            >
                {galleryImages
                    .filter((image) => tab === Tabs.ALL || image.type === tab)
                    .map((image, index) => (
                        <a
                            href={image.src}
                            target="_blank"
                            rel="noreferrer"
                            key={index}
                        >
                            {image.type === Tabs.VIDEO ? (
                                <video
                                    width="300"
                                    height="300"
                                    controls
                                    style={{
                                        borderRadius: '5px',
                                        objectFit: 'cover',
                                    }}
                                >
                                    <source src={image.src} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img
                                    src={image.src}
                                    alt={`gallery-${index}`}
                                    style={{
                                        width: '300px',
                                        height: '300px',
                                        objectFit: 'cover',
                                        borderRadius: '5px',
                                    }}
                                />
                            )}
                        </a>
                    ))}
            </div>
        </Layout>
    );
};

export default Gallery;
